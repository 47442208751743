import { Buffer } from 'buffer';

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useNotifications } from '../../common/hooks/useNotifications';
import { useSpdCore } from '../../common/hooks/useSpdCore';
import versionWeb from '../../assets/version.json';

export const DocumentationView: React.FC = () => {
  const spd = useSpdCore();
  const notifications = useNotifications();
  const [content, setContent] = useState('');
  const [coreInfo, setCoreInfo] = useState('');

  const params = new URLSearchParams(window.location.search);
  const filename = params.get('plik');

  const getPdf = () => {
    spd.docs
      .getForFilename('spd.pdf')
      .then(doc => {
        const file = new Blob([Buffer.from(doc.content, 'base64')], {
          type: 'application/pdf',
        });
        window.open(URL.createObjectURL(file));
      })
      .catch(errorResponse => {
        console.log(errorResponse);
      });
  };

  useEffect(() => {
    spd.docs
      .getForFilename(filename || 'index.html')
      .then(doc => {
        let content = Buffer.from(doc.content, 'base64').toString();
        content = content.replaceAll('href="ch', 'href="/pomoc/pomoc?plik=ch');
        content = content.replaceAll(
          'href="idp',
          'href="/pomoc/pomoc?plik=idp',
        );
        content = content.replaceAll(
          'href="index',
          'href="/pomoc/pomoc?plik=index',
        );
        content = content.replaceAll('../../common/', '');
        content = content.replaceAll('src="images/', `src="../images/`);

        setContent(content);

        if (window.location.hash) {
          setTimeout(() => {
            const elem = document.getElementById(window.location.hash.slice(1));
            elem?.scrollIntoView();
          }, 0);
        }
      })
      .catch(() => console.log('Niepoprawny link'));

    spd.docs
      .getVersionInfo()
      .then(versionCore => {
        if (versionCore.date) {
          setCoreInfo(
            `DB: kompilacja z ${versionCore.buildTime} (repo: ${versionCore.date}, ${versionCore.commit})`,
          );
        }
      })
      .catch(errorResponse => {
        console.log(errorResponse);
      });
  }, [spd.docs, filename, notifications]);

  const webInfo = versionWeb['date']
    ? `SPD: kompilacja z ${versionWeb['buildTime']} (repo: ${versionWeb['date']}, ${versionWeb['commit']})`
    : '';

  return (
    <>
      <div className="m-5" dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className="d-flex mt-3">
        <div className="flex-grow-1 small text-secondary">
          {webInfo}
          <br />
          {coreInfo}
        </div>
        <div>
          <Button variant="link" onClick={getPdf}>
            Dokumentacja w formacie PDF
            <img src="images/pdf-file.png" alt="Dokumentacja PDF" />
          </Button>
        </div>
      </div>
    </>
  );
};
