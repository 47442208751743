/* eslint-disable complexity */
/*eslint max-lines-per-function: ["error", 500]*/
import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { matchPath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { PasswordResetView } from '../app/containers/PasswordResetView';
import { RegisterView } from '../app/containers/RegisterView';
import { SignInView } from '../app/containers/SignInView';
import { ArticlesView } from '../spd/containers/ArticlesView/ArticlesView';
import { BatteriesView } from '../spd/containers/BatteriesView/BatteriesView';
import { CardsView } from '../spd/containers/CardsView/CardsView';
import { ChangeEmailView } from '../spd/containers/ChangeEmailView/ChangeEmailView';
import { ChangePasswordView } from '../spd/containers/ChangePasswordView/ChangePasswordView';
import { ExamCardsView } from '../spd/containers/ExamCardsView/ExamCardsView';
import { ExaminedsView } from '../spd/containers/ExaminedsView/ExaminedsView';
import { ExamJudgmentsDoctorView } from '../spd/containers/ExamJudgmentsView/ExamJudgmentsDoctorView';
import { ExamJudgmentsPsychologistView } from '../spd/containers/ExamJudgmentsView/ExamJudgmentsPsychologistView';
import { ExamsDoctorView } from '../spd/containers/ExamsView/ExamsDoctorView';
import { ExamsPsychologistView } from '../spd/containers/ExamsView/ExamsPsychologistView';
import { ExamsView } from '../spd/containers/ExamsView/ExamsView';
import { ExamTestsView } from '../spd/containers/ExamTestsView/ExamTestsView';
import { IndicatorTestsInExamView } from '../spd/containers/IndicatorTestInExamView/IndicatorTestsInExamView';
import { IndicatorTestsView } from '../spd/containers/IndicatorTestsView/IndicatorTestsView';
import { JudgmentsView } from '../spd/containers/JudgmentsView/JudgmentsView';
import { KeyTestsView } from '../spd/containers/KeyTestsView/KeyTestsView';
import { KeyTestView } from '../spd/containers/KeyTestsView/KeyTestView';
import { LandingView } from '../spd/containers/LandingView/LandingView';
import { NormsView } from '../spd/containers/NormsView/NormsView';
import { NormView } from '../spd/containers/NormsView/NormView';
import { ResultsView } from '../spd/containers/ResultsView/ResultsView';
import { TestGivingModeView } from '../spd/containers/TestGivingModeView/TestGivingModeView';
import { TestGivingView } from '../spd/containers/TestGivingView/TestGivingView';
import { TestView } from '../spd/containers/TestsView/TestView';
import { UnauthorizedView } from '../spd/containers/UnauthorizedView/UnauthorizedView';
import { UsersView } from '../spd/containers/UsersView/UsersView';
import { ExamEditorView } from '../spd/containers/ExamsView/ExamEditorView';
import { ConsultationsView } from '../spd/containers/ConsultationsView/ConsultationsView';
import { ExamConsultationsView } from '../spd/containers/ExamConsultationsView/ExamConsultationsView';
import { AttachmentsView } from '../spd/containers/AttachmentsView/AttachmentsView';
import { ExamsPackageView } from '../spd/containers/ExamsPackageView/ExamsPackageView';
import { CompaniesView } from '../spd/containers/CompaniesView/CompaniesView';
import { ExamsInPackageView } from '../spd/containers/ExamsView/ExamsInPackageView';
import { RegistersView } from '../spd/containers/RegistersView/RegistersView';
import { JudgmentsForRegisterView } from '../spd/containers/RegistersView/JudgmentsForRegisterView';
import { SavePinView } from '../spd/containers/SavePinView/SavePinView';
import { JudgmentsNumberingView } from '../spd/containers/JudgmentsNumberingView/JudgmentsNumberingView';
import { EmailsView } from '../spd/containers/EmailsView/EmailsView';
import { JudgmentsForSigningView } from '../spd/containers/JudgmentsView/JudgmentsForSigningView';
import { ExamsOphthalmologistView } from '../spd/containers/ExamsView/ExamsOphthalmologistView';
import { HealthcareFacilitiesView } from '../spd/containers/HealthcareFacility/HealthcareFacilitiesView';
import { DocumentationView } from '../app/containers/DocumentationView';
import { ExamsForExaminedView } from '../spd/containers/ExamsView/ExamForExamined';
import { AccessView } from '../spd/containers/AccessView/AccessView';
import { GenerateExternalReferralView } from '../spd/containers/ExternalReferral/GenerateExternalReferralView';
import { HarmfulFactorsView } from '../spd/containers/HarmfulFactorView/HarmfulFactorsView';
import { DutiesView } from '../spd/containers/DutyView/DutiesView';
import { ReportJudgmentsView } from '../spd/containers/ReportsView/ReportJudgmentsView';
import { ReportConsultationsView } from '../spd/containers/ReportsView/ReportConsultationsView';
import { ReportCompaniesView } from '../spd/containers/ReportsView/ReportCompaniesView';
import { SupportingExaminationDictView } from '../spd/containers/SupportingExaminationDictView/SupportingExaminationDictView';
import { MergeExaminedView } from '../spd/containers/ExaminedsView/MergeExaminedView';
import { ReportCompaniesDetailsView } from '../spd/containers/ReportsView/ReportCompaniesDetailsView';
import { JudgmentsWithoutElectronicSignView } from '../spd/containers/JudgmentsView/JudgmentsWithoutElectronicSignView';
import { ReportJudgmentsByCreatorView } from '../spd/containers/ReportsView/ReportJudgmentsByCreatorView';
import { ReferralsView } from '../spd/containers/ReferralsView/ReferralsView';
import { CalendarView } from '../spd/containers/CalendarView/CalendarView';
import { ExamsTypesView } from '../spd/containers/ExamsTypesView/ExamsTypesView';
import { AppointmentChannelsView } from '../spd/containers/AppointmentChannelsView/AppointmentChannelsView';
import { ChangeLogView } from '../app/containers/ChangeLogView';
import { ExamTypesCategoriesView } from '../spd/containers/ExamTypeCategoryView/ExamTypesCategoriesView';
// import { RegistrationsView } from '../spd/containers/RegistrationsView/RegistrationsView';
import { StethoView } from '../spd/containers/StethoView/StethoView';
import { ChangeLogExternalView } from '../app/containers/ChangeLogExternalView';

import { useAuth } from './hooks/useAuth';
import { Role } from './spdCore/authorization/roles';
import { useCookies } from './hooks/useCookies';
import { useNotifications } from './hooks/useNotifications';
import { ArticleMode } from './spdCore/validation/schemas';

export interface MenuItem {
  hr?: boolean;
  heading?: string;
  path: string;
  skipRegistration?: boolean;
  icon?: Icon.Icon;
  element?: JSX.Element;
  requiredRole?: Role[];
  submenu?: MenuItem[];
  className?: string;
}

export const routesInMenu: MenuItem[] = [
  {
    heading: 'Wystawianie skierowań',
    path: '/wystawianie-skierowan',
    element: React.createElement(GenerateExternalReferralView),
    requiredRole: [Role.ExternalRegistration],
    submenu: [],
  },
  {
    heading: 'Rejestracja',
    path: '/registration',
    requiredRole: [Role.MedicalRegistration, Role.Admin],
    submenu: [
      {
        heading: 'Badani',
        path: '/badani',
        element: React.createElement(ExaminedsView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
      },
      {
        heading: 'Zadawanie testów',
        path: '/chooseTestGiving',
        element: React.createElement(TestGivingModeView),
        requiredRole: [Role.MedicalRegistration, Role.Admin],
        submenu: [],
      },
      // {
      //   heading: 'Rejestracja',
      //   path: '/registrations',
      //   element: React.createElement(RegistrationsView),
      //   requiredRole: [Role.Admin],
      //   submenu: [],
      // },
      {
        hr: true,
        path: '',
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Kalendarz dni roboczych',
        path: '/calendar',
        element: React.createElement(CalendarView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        heading: 'Kategorie typów badań',
        path: '/examTypesCategories',
        element: React.createElement(ExamTypesCategoriesView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        heading: 'Typy badań',
        path: '/examTypes',
        element: React.createElement(ExamsTypesView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        heading: 'Kanały rezerwacji wizyt',
        path: '/appointmentChannels',
        element: React.createElement(AppointmentChannelsView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
    ],
  },
  {
    heading: 'Monitor',
    path: '/monitor',
    element: React.createElement(LandingView),
    requiredRole: [
      Role.PsychologistAssessor,
      Role.PsychologistSpecialist,
      Role.MedicalAssessor,
      Role.MedicalSpecialist,
      Role.MedicalRegistration,
      Role.Admin,
    ],
    submenu: [
      {
        heading: 'Monitor - psychologa',
        path: `/monitor-psycholog`,
        element: React.createElement(ExamsPsychologistView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Monitor - lekarza',
        path: '/monitor-lekarz',
        element: React.createElement(ExamsDoctorView),
        requiredRole: [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Monitor - okulisty',
        path: '/monitor-okulista',
        element: React.createElement(ExamsOphthalmologistView),
        requiredRole: [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
        submenu: [],
      },
      // {
      //   heading: 'Monitor - laryngologa',
      //   path: '/monitor-lekarz',
      //   element: React.createElement(ExamsDoctorView),
      //   requiredRole: [
      //     Role.MedicalAssessor,
      //     Role.MedicalSpecialist,
      //     Role.MedicalRegistration,
      //   ],
      //   submenu: [],
      // },
      // {
      //   heading: 'Monitor - neurologa',
      //   path: '/monitor-lekarz',
      //   element: React.createElement(ExamsDoctorView),
      //   requiredRole: [
      //     Role.MedicalAssessor,
      //     Role.MedicalSpecialist,
      //     Role.MedicalRegistration,
      //   ],
      //   submenu: [],
      // },
      {
        heading: 'Monitor - ogólny',
        path: '/monitor-ogolny',
        element: React.createElement(ExamsView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
        submenu: [],
      },
    ],
  },
  {
    heading: 'Wydruki',
    path: '/wydruki',
    element: React.createElement(LandingView),
    requiredRole: [
      Role.PsychologistAssessor,
      Role.PsychologistSpecialist,
      Role.MedicalAssessor,
      Role.MedicalSpecialist,
      Role.MedicalRegistration,
      Role.Admin,
    ],
    submenu: [
      {
        heading: 'Orzeczenia',
        path: '/orzeczenia',
        element: React.createElement(JudgmentsView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Orzeczenia bez podpisu elektronicznego',
        path: '/orzeczenia_bez_podpisu',
        element: React.createElement(JudgmentsWithoutElectronicSignView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Karty',
        path: '/karty',
        element: React.createElement(CardsView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Konsultacje',
        path: '/konsultacje',
        element: React.createElement(ConsultationsView),
        requiredRole: [
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Skierowania',
        path: '/skierowania',
        element: React.createElement(ReferralsView),
        requiredRole: [
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
          Role.Admin,
        ],
        submenu: [],
      },
    ],
  },
  {
    heading: 'Rejestry',
    path: '/rejestry',
    skipRegistration: true,
    element: React.createElement(LandingView),
    requiredRole: [
      Role.PsychologistAssessor,
      Role.PsychologistSpecialist,
      Role.MedicalAssessor,
      Role.MedicalSpecialist,
      Role.Admin,
    ],
    submenu: [
      {
        heading: 'Dokumenty oczekujące na podpis',
        path: '/dokumenty-do-podpisu',
        element: React.createElement(JudgmentsForSigningView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        hr: true,
        path: '',
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.Admin,
        ],
      },
      {
        heading: 'Psycholodzy - orzeczenia kierowców',
        path: `/rejestr-orzeczen/OK`,
        element: React.createElement(JudgmentsForRegisterView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Psycholodzy - medycyna pracy',
        path: `/rejestr-orzeczen/KMP`,
        element: React.createElement(JudgmentsForRegisterView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Psycholodzy - broń',
        path: `/rejestr-orzeczen/BR`,
        element: React.createElement(JudgmentsForRegisterView),
        requiredRole: [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        hr: true,
        path: '',
        requiredRole: [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.Admin,
        ],
      },
      {
        heading: 'Lekarze - kierowcy z art. 75',
        path: '/rejestr-orzeczen/K75',
        element: React.createElement(JudgmentsForRegisterView),
        requiredRole: [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Lekarze - medycyna pracy',
        path: '/rejestr-orzeczen/K43',
        element: React.createElement(JudgmentsForRegisterView),
        requiredRole: [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Lekarze - sanitarny',
        path: '/rejestr-orzeczen/KS',
        element: React.createElement(JudgmentsForRegisterView),
        requiredRole: [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Lekarze - uczniowie i studenci',
        path: '/rejestr-orzeczen/KU',
        element: React.createElement(JudgmentsForRegisterView),
        requiredRole: [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.Admin,
        ],
        submenu: [],
      },
      {
        heading: 'Lekarze - broń',
        path: '/rejestr-orzeczen/LBR',
        element: React.createElement(JudgmentsForRegisterView),
        requiredRole: [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.Admin,
        ],
        submenu: [],
      },
    ],
  },
  {
    heading: 'Zestawienia',
    path: '/zestawienia',
    skipRegistration: true,
    element: React.createElement(LandingView),
    requiredRole: [Role.Admin],
    submenu: [
      {
        heading: 'Orzeczeń',
        path: '/wg-orzeczen',
        element: React.createElement(ReportJudgmentsView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        heading: 'Konsultacji',
        path: '/wg-konsultacji',
        element: React.createElement(ReportConsultationsView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        heading: 'Firm',
        path: '/wg-firm',
        element: React.createElement(ReportCompaniesView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        hr: true,
        path: '',
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Orzeczeń wg utworzenia',
        path: '/wg-utworzenia',
        element: React.createElement(ReportJudgmentsByCreatorView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
    ],
  },
  {
    heading: 'Obsługa paczek',
    path: '/obsluga_paczek',
    element: React.createElement(LandingView),
    requiredRole: [Role.MedicalRegistration, Role.Admin],
    submenu: [
      {
        heading: 'Paczki badań',
        path: '/paczki',
        element: React.createElement(ExamsPackageView),
        requiredRole: [Role.MedicalRegistration, Role.Admin],
      },
      {
        heading: 'Firmy',
        path: '/firmy',
        element: React.createElement(CompaniesView),
        requiredRole: [Role.Admin],
      },
    ],
  },
  {
    heading: 'Zarządzanie testami',
    path: '/testy',
    element: React.createElement(LandingView),
    requiredRole: [Role.Admin],
    submenu: [
      {
        heading: 'Testy',
        path: '/testy/testy',
        element: React.createElement(TestView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        heading: 'Baterie testów',
        path: '/testy/baterie',
        element: React.createElement(BatteriesView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        heading: 'Klucze',
        path: '/testy/klucze',
        element: React.createElement(KeyTestsView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        heading: 'Wskaźniki',
        path: '/testy/wskazniki',
        element: React.createElement(IndicatorTestsView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
      {
        heading: 'Normy',
        path: '/testy/normy',
        element: React.createElement(NormsView),
        requiredRole: [Role.Admin],
        submenu: [],
      },
    ],
  },
  {
    heading: 'Administracja',
    path: '/administracja',
    element: React.createElement(LandingView),
    requiredRole: [Role.Admin, Role.MedicalRegistration],
    submenu: [
      {
        heading: 'Wiadomości e-mail',
        path: '/administracja/emaile',
        element: React.createElement(EmailsView),
        requiredRole: [Role.Admin, Role.MedicalRegistration],
      },
      {
        hr: true,
        path: '',
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Artykuły',
        path: '/administracja/artykuly',
        element: React.createElement(ArticlesView),
        requiredRole: [Role.Admin],
      },

      {
        heading: 'Czynniki szkodliwe',
        path: '/administracja/czynniki-szkodliwe',
        element: React.createElement(HarmfulFactorsView),
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Rodzaje badań pomocniczych',
        path: '/administracja/rodzaje-badan-pomocniczych',
        element: React.createElement(SupportingExaminationDictView),
        requiredRole: [Role.Admin],
      },
      {
        hr: true,
        path: '',
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Rejestry',
        path: '/administracja/rejestry',
        element: React.createElement(RegistersView),
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Numeracja rejestrów',
        path: '/administracja/numeracja-rejestrow',
        element: React.createElement(JudgmentsNumberingView),
        requiredRole: [Role.Admin],
      },
      {
        hr: true,
        path: '',
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Placówki',
        path: '/administracja/placowki',
        element: React.createElement(HealthcareFacilitiesView),
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Dyżury',
        path: '/administracja/dyzury',
        element: React.createElement(DutiesView),
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Użytkownicy',
        path: '/administracja/uzytkownicy',
        element: React.createElement(UsersView),
        requiredRole: [Role.Admin],
      },
      {
        heading: 'Dostępy',
        path: '/administracja/dostepy',
        element: React.createElement(AccessView),
        requiredRole: [Role.Admin],
      },
    ],
  },
  {
    heading: 'Pomoc',
    path: '/pomoc',
    element: React.createElement(LandingView),
    requiredRole: [Role.Admin, Role.MedicalRegistration],
    submenu: [
      {
        heading: 'Dokumentacja',
        path: `/pomoc/pomoc`,
        element: React.createElement(DocumentationView),
        requiredRole: [
          Role.Admin,
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
      },
      {
        heading: 'Lista zmian - portal wewnętrzny',
        path: `/lista_zmian_portal_wewnetrzny`,
        element: React.createElement(ChangeLogView),
        requiredRole: [
          Role.Admin,
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        submenu: [],
      },
      ...(process.env.REACT_APP_TEST_CHANGELOG
        ? [
            {
              heading: 'Lista zmian - stanowisko testów',
              path: '/lista_zmian_testy',
              element: React.createElement(ChangeLogExternalView),
              requiredRole: [
                Role.Admin,
                Role.PsychologistAssessor,
                Role.PsychologistSpecialist,
                Role.MedicalAssessor,
                Role.MedicalSpecialist,
                Role.MedicalRegistration,
              ],
              submenu: [],
            },
          ]
        : []),
    ],
  },
];

export const routesInLoginMenu: MenuItem[] = [
  {
    heading: 'Zmiana hasła',
    path: '/zmianaHasla',
    element: React.createElement(ChangePasswordView),
    requiredRole: [
      Role.PsychologistAssessor,
      Role.PsychologistSpecialist,
      Role.MedicalAssessor,
      Role.MedicalSpecialist,
      Role.MedicalRegistration,
      Role.Admin,
      Role.ExternalRegistration,
    ],
  },
  {
    heading: 'Zmiana e-mail',
    path: '/zmianaEmail',
    element: React.createElement(ChangeEmailView),
    requiredRole: [
      Role.PsychologistAssessor,
      Role.PsychologistSpecialist,
      Role.MedicalAssessor,
      Role.MedicalSpecialist,
      Role.MedicalRegistration,
      Role.Admin,
      Role.ExternalRegistration,
    ],
  },
  {
    heading: 'Zapisz PIN',
    path: '/zapiszPin',
    element: React.createElement(SavePinView),
    requiredRole: [
      Role.PsychologistAssessor,
      Role.PsychologistSpecialist,
      Role.MedicalAssessor,
      Role.MedicalSpecialist,
      Role.MedicalRegistration,
      Role.Admin,
    ],
  },
];

export const routesOutsideMenu: MenuItem[] = [
  {
    heading: 'SPD',
    path: '/',
    element: React.createElement(LandingView),
  },
  {
    heading: 'Logowanie',
    path: '/login',
    element: React.createElement(SignInView),
  },
  {
    heading: 'Rejestracja',
    path: '/register',
    element: React.createElement(RegisterView),
  },
  {
    heading: 'Reset hasła',
    path: '/passwordReset',
    element: React.createElement(PasswordResetView),
  },
  {
    heading: 'Testy badania',
    path: '/testyBadania/:id/:mode',
    element: React.createElement(ExamTestsView),
  },
  {
    heading: 'Badania w paczce',
    path: '/badaniaWPaczce/:id',
    element: React.createElement(ExamsInPackageView),
  },
  {
    heading: 'Wyniki',
    path: '/wyniki/:id',
    element: React.createElement(ResultsView),
  },
  {
    heading: 'Wskaźniki',
    path: '/wskazniki/:id',
    element: React.createElement(IndicatorTestsInExamView),
  },
  {
    heading: 'Karta badania',
    path: '/examCards/:type/:id',
    element: React.createElement(ExamCardsView),
  },
  {
    heading: 'Konsultacja',
    path: '/konsultacja/:type/:id',
    requiredRole: [
      Role.MedicalSpecialist,
      Role.MedicalRegistration,
      Role.Admin,
    ],
    element: React.createElement(ExamConsultationsView),
  },
  {
    heading: 'Edycja badania',
    path: '/examEditor/:id',
    element: React.createElement(ExamEditorView),
  },
  {
    heading: 'Załączniki do badanego',
    path: '/attachments/:examinedId',
    element: React.createElement(AttachmentsView),
  },
  {
    heading: 'Badania związane z badanym',
    path: '/examsExamined/:id',
    element: React.createElement(ExamsForExaminedView),
  },
  {
    heading: 'Sklejanie badanego',
    path: '/mergeExamined/:id',
    element: React.createElement(MergeExaminedView),
  },
  {
    heading: 'Orzeczenia psychologiczne do badania',
    path: '/examPsychologistJudgments/:id',
    requiredRole: [
      Role.PsychologistAssessor,
      Role.PsychologistSpecialist,
      Role.MedicalRegistration,
      Role.Admin,
    ],
    element: React.createElement(ExamJudgmentsPsychologistView),
  },
  {
    heading: 'Orzeczenia lekarskie do badania',
    path: '/examDoctorJudgments/:id',
    requiredRole: [
      Role.MedicalAssessor,
      Role.MedicalSpecialist,
      Role.MedicalRegistration,
      Role.Admin,
    ],
    element: React.createElement(ExamJudgmentsDoctorView),
  },
  {
    heading: 'Zadawanie testu badanemu indywidualnemu',
    path: '/testGiving/:modeOnlyOne',
    requiredRole: [Role.MedicalRegistration, Role.Admin],
    element: React.createElement(TestGivingView),
  },
  {
    heading: 'Zadawanie testów zbiorczo',
    path: '/testGiving/:modeOnlyOne',
    requiredRole: [Role.Admin],
    element: React.createElement(TestGivingView),
  },
  {
    heading: 'Klucz testu',
    path: '/kluczTestu/:id',
    requiredRole: [Role.Admin],
    element: React.createElement(KeyTestView),
  },
  {
    heading: 'Norma',
    path: '/norma/:id',
    requiredRole: [Role.Admin],
    element: React.createElement(NormView),
  },
  {
    heading: 'Brak dostępu',
    path: '/401',
    element: React.createElement(UnauthorizedView),
  },
  {
    heading: 'Rejestr - orzeczeń',
    path: `/rejestr-orzeczen/:registerMode`,
    element: React.createElement(JudgmentsForRegisterView),
  },
  {
    heading: 'Dokumenty oczekujące na podpis',
    path: `/dokumenty-do-podpisu`,
    element: React.createElement(JudgmentsForSigningView),
  },
  {
    heading: 'Raport wg orzeczeń',
    path: `/wg-orzeczen`,
    element: React.createElement(ReportJudgmentsView),
  },
  {
    heading: 'Raport wg konsultacji',
    path: `/wg-konsultacji`,
    element: React.createElement(ReportConsultationsView),
  },
  {
    heading: 'Raport wg firm',
    path: `/wg-firm`,
    element: React.createElement(ReportCompaniesView),
  },
  {
    heading: 'Raport orzeczeń wg tworzenia',
    path: `/wg-utworzenia`,
    element: React.createElement(ReportJudgmentsByCreatorView),
  },
  {
    heading: 'Szczegóły raportu wg firm',
    path: `/zestawienia/wg-firm/szczegoly/:psychologistId/:companyId/:registerId/:healthcareFacilityId/:dateFromYear/:dateFromMonth/:dateFromDay/:dateToYear/:dateToMonth/:dateToDay/:onlyWithSettlement`,
    element: React.createElement(ReportCompaniesDetailsView),
  },
  {
    heading: 'Analiza wyników StethoMe',
    path: '/stetho/podglad/:examId/:testId',
    requiredRole: [
      Role.PsychologistAssessor,
      Role.PsychologistSpecialist,
      Role.MedicalAssessor,
      Role.MedicalSpecialist,
      Role.MedicalRegistration,
      Role.Admin,
    ],
    element: React.createElement(StethoView),
  },
  {
    heading: 'spd',
    path: '*',
    element: React.createElement(LandingView),
  },
];

export const currentMenuItems = (login: boolean, roles?: Role[]) => {
  const items: MenuItem[] = [];
  if (login) {
    for (const position of routesInMenu) {
      if (
        position.requiredRole === null ||
        (position.requiredRole &&
          roles &&
          position.requiredRole.some(item => roles.includes(item)))
      ) {
        if (position.submenu && position.submenu.length > 0) {
          for (const subposition of position.submenu) {
            if (
              subposition.requiredRole &&
              roles &&
              !subposition.requiredRole.some(item => roles.includes(item))
            ) {
              position.submenu?.splice(
                position.submenu.indexOf(subposition),
                1,
              );
            }
          }
        }
        items.push(position);
      }
    }
  }
  return items;
};

export const getCurrentMenuItem = (activePage: string) => {
  const items: MenuItem[] = [];

  const menu = routesInMenu.concat(routesInLoginMenu).concat(routesOutsideMenu);

  for (const position of menu) {
    if (matchPath(activePage, position.path)) {
      items.push(position);
    }
    for (const subposition of position.submenu || []) {
      if (matchPath(activePage, subposition.path)) {
        items.push(subposition);
      }
    }
  }
  return items;
};

export const currentProfileMenuItems = (activePage: string, roles?: Role[]) => {
  const items: MenuItem[] = [];
  for (const position of routesInLoginMenu) {
    if (
      position.requiredRole === null ||
      (position.requiredRole &&
        roles &&
        position.requiredRole.some(item => roles.includes(item)))
    ) {
      items.push(position);

      for (const subposition of position.submenu || []) {
        if (matchPath(activePage, subposition.path)) {
          items.push(subposition);
        }
      }
    }
  }
  return items;
};

export const useNavigation = () => {
  const URL_DEFAULT = '/start';
  const URL_PSYCHOLOGIST = '/badania';
  const URL_UNAUTHORIZED = '/401';
  const URL_EXAMINED = process.env.REACT_APP_TEST_APP_URL;

  const navigate = useNavigate();
  const auth = useAuth();
  const cookies = useCookies();
  const notifications = useNotifications();

  const checkAccess = (roles: Role[], url: string) => {
    if (!auth.check(roles)) {
      notifications.unauthorized();
      // to de facto nie działa, bo ten kod jest i był wyłączony
      //   return navigate(<Alert variant="danger">
      //   <Alert.Heading>Brak wymaganych uprawnień</Alert.Heading>
      //   <hr />
      //   <p>
      //     W celu podwyższenia uprawnień prosimy o kontakt z administracją.
      //   </p>
      // </Alert>)
      return;
    }
    navigate(url);
  };

  const chooseStartPage = (
    urlDefault: string,
    urlStaff: string,
    urlUnauthorized: string,
    urlExamined?: string,
  ) => {
    if (
      auth.check([
        Role.PsychologistAssessor,
        Role.PsychologistSpecialist,
        Role.MedicalAssessor,
        Role.MedicalSpecialist,
        Role.MedicalRegistration,
      ])
    ) {
      navigate(urlStaff);
    } else if (auth.check([Role.Examined])) {
      window.open(urlExamined, '_self');
    } else if (auth.check([Role.Inactive])) {
      window.open(urlUnauthorized, '_self');
    } else {
      navigate(urlDefault);
    }
    return;
  };

  const chooseMonitor = (mode?: ArticleMode) => {
    let modeToChoose = mode;
    let url = '';
    let roles = [];

    if (!mode) {
      switch (cookies.activeMonitor) {
        case 'psychologist':
          modeToChoose = ArticleMode.Psychologist;
          break;
        case 'doctor':
          modeToChoose = ArticleMode.Doctor;
          break;
        case 'ophthalmologist':
          modeToChoose = ArticleMode.Ophthalmologist;
          break;
      }
    }

    switch (modeToChoose) {
      case ArticleMode.Psychologist:
        url = '/monitor-psycholog';
        roles = [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalRegistration,
        ];
        break;

      case ArticleMode.Doctor:
        url = '/monitor-lekarz';
        roles = [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ];
        break;
      case ArticleMode.Ophthalmologist:
        url = '/monitor-okulista';
        roles = [Role.MedicalSpecialist, Role.MedicalRegistration];
        break;

      default:
        url = '/monitor-ogolny';
        roles = [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ];
        break;
    }
    return checkAccess(roles, url);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const goWith = (url: string, newWindow?: boolean, options?: any) => {
    //if (!auth.check(role)) {
    //  notifications.unauthorized();
    //  return;
    //}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actions = (url: string, options?: any) => navigate(url, options);

    if (!newWindow) {
      actions(url, options);
    } else {
      window.open(url, '_blank');
    }
  };

  return {
    menuItem: (location: string) => navigate(location),
    help: () => navigate('/pomoc'),
    startPage: () =>
      chooseStartPage(
        URL_DEFAULT,
        URL_PSYCHOLOGIST,
        URL_UNAUTHORIZED,
        URL_EXAMINED,
      ),
    login: () => navigate('/login'),
    goBack: () => navigate(-1),
    examined: () =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        '/badani',
      ),
    attachments: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/attachments/${id}`,
      ),
    examsExamined: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/examsExamined/${id}`,
      ),
    mergeExamined: (id: string) =>
      checkAccess([Role.Admin], `/mergeExamined/${id}`),
    exams: (mode?: ArticleMode) => chooseMonitor(mode),
    chats: () =>
      window.open(
        `${process.env.REACT_APP_CHAT_APP_URL}/monitor?token=${
          localStorage.getItem('mzt-token') || ''
        }`,
        'blank',
      ),
    examsInPackage: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/badaniaWPaczce/${id}`,
      ),
    examPsychologistJudgments: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalRegistration,
        ],
        `/examPsychologistJudgments/${id}`,
      ),
    examDoctorJudgments: (id: string) =>
      checkAccess(
        [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/examDoctorJudgments/${id}`,
      ),
    examCardsPsychologic: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalRegistration,
        ],
        `/examCards/karta_badania_psychologicznego/${id}`,
      ),
    examCardsPsychologicWeapon: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalRegistration,
        ],
        `/examCards/bron_karta_badania_psychologicznego/${id}`,
      ),
    examCardsWeapon: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalRegistration,
        ],
        `/examCards/karta_broni/${id}`,
      ),
    examCardsPreventive: (id: string) =>
      checkAccess(
        [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/examCards/karta_badania_profilaktycznego/${id}`,
      ),
    examCardsDriver: (id: string) =>
      checkAccess(
        [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/examCards/karta_badania_kierowcy/${id}`,
      ),
    examCardsSanitary: (id: string) =>
      checkAccess(
        [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/examCards/karta_badania_sanitarno_epidemiologicznego/${id}`,
      ),
    examCardsSport: (id: string) =>
      checkAccess(
        [
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/examCards/karta_badania_sportowego/${id}`,
      ),
    examEditor: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/examEditor/${id}`,
      ),
    tests: () => checkAccess([Role.Admin], '/testy'),
    testGiving: () =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        '/choosetestGiving',
      ),
    testGivingMass: () => checkAccess([Role.Admin], `/testGiving/false`),
    testGivingIndividual: () =>
      checkAccess([Role.MedicalRegistration, Role.Admin], `/testGiving/true`),
    pin: () => checkAccess([Role.Examined], '/pin'),

    profileView: (id: string | undefined) => navigate(`/profile/${id}`),

    passwordReset: () => navigate('/passwordReset'),
    register: () => navigate('/register'),
    examTestsDoctor: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/testyBadania/${id}/Doctor`,
      ),
    examTestsPsychologist: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalRegistration,
        ],
        `/testyBadania/${id}/Psychologist`,
      ),
    examTestsOphthalmologist: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalRegistration,
        ],
        `/testyBadania/${id}/Ophthalmologist`,
      ),
    examTests: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/testyBadania/${id}/null`,
      ),
    results: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/wyniki/${id}`,
      ),
    indicatorTestsInExam: (id: string) =>
      checkAccess(
        [
          Role.PsychologistAssessor,
          Role.PsychologistSpecialist,
          Role.MedicalAssessor,
          Role.MedicalSpecialist,
          Role.MedicalRegistration,
        ],
        `/wskazniki/${id}`,
      ),

    keyTest: (id: string) => checkAccess([Role.Admin], `/kluczTestu/${id}`),
    norm: (id: string) => checkAccess([Role.Admin], `/norma/${id}`),

    consultationNeurological: (id: string) =>
      checkAccess(
        [Role.MedicalSpecialist, Role.MedicalRegistration],
        `/konsultacja/neurologiczna/${id}`,
      ),
    consultationOphthalmologist: (id: string) =>
      checkAccess(
        [Role.MedicalSpecialist, Role.MedicalRegistration],
        `/konsultacja/okulistyczna/${id}`,
      ),
    consultationENT: (id: string) =>
      checkAccess(
        [Role.MedicalSpecialist, Role.MedicalRegistration],
        `/konsultacja/laryngologiczna/${id}`,
      ),

    consultationPsychiatric: (id: string) =>
      checkAccess(
        [
          Role.MedicalSpecialist,
          Role.PsychologistSpecialist,
          Role.MedicalRegistration,
        ],
        `/konsultacja/psychiatryczna/${id}`,
      ),

    stethoView: (examId: string, testId: string) =>
      goWith(`/stetho/podglad/${examId}/${testId}`, true),

    reportCompanyDetails: (
      psychologistId?: string,
      companyId?: string,
      registerId?: string,
      healthcareFacilityId?: string,
      dateFromYear?: string,
      dateFromMonth?: string,
      dateFromDay?: string,
      dateToYear?: string,
      dateToMonth?: string,
      dateToDay?: string,
      onlyMonthlySettlement?: boolean,
      newWindow?: boolean,
    ) =>
      goWith(
        `/zestawienia/wg-firm/szczegoly/${psychologistId || 'undefined'}/${
          companyId || 'undefined'
        }/${registerId || 'undefined'}/${
          healthcareFacilityId || 'undefined'
        }/${dateFromYear}/${dateFromMonth}/${dateFromDay}/${dateToYear}/${dateToMonth}/${dateToDay}/${onlyMonthlySettlement}`,
        newWindow,
      ),

    reload: () => history.go(0),
  };
};
