import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';

export interface StethoContextInterface {
  getViewStethoTest: (examId: string, testId: string) => Promise<string[]>;
  getCardioResult: (examId: string, testId: string) => Promise<string>;
  getPulmonaryResult: (examId: string, testId: string) => Promise<string>;
}

export const StethoContext = (api: DefaultApi) => {
  return {
    getViewStethoTest: async (examId: string, testId: string) => {
      return await api.stethoControllerGetViewStethoTest(examId, testId);
    },
    getCardioResult: async (examId: string, testId: string) => {
      return await api.stethoControllerGetCardioResult(examId, testId);
    },
    getPulmonaryResult: async (examId: string, testId: string) => {
      return await api.stethoControllerGetPulmonaryResult(examId, testId);
    },
  };
};
