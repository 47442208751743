import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

export const StethoPreviewLink = (props: {
  examId?: string;
  testId?: string;
}) => {
  const nav = useNavigation();

  const handleClick = () => {
    props.examId && props.testId && nav.stethoView(props.examId, props.testId);
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Analiza wyników StethoMe</Tooltip>}>
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Activity />
        </Button>
      </OverlayTrigger>
    </>
  );
};
