import React, { useEffect, useRef, useState } from 'react';
import { Card, Ratio } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

type ParamTypes = {
  examId: string;
  testId: string;
};

export const StethoView: React.FC = () => {
  const spd = useSpdCore();
  const mountedRef = useRef(false);

  const [frames, setFrames] = useState<string[]>();
  const [cardio, setCardio] = useState<string>();
  const [pulmonary, setPulmonary] = useState<string>();

  const { examId, testId } = useParams<ParamTypes>() as {
    examId: string;
    testId: string;
  };

  useEffect(() => {
    mountedRef.current = true;

    spd.stetho
      .getViewStethoTest(examId, testId)
      .then(res => {
        if (mountedRef.current) {
          setFrames(res);
        }
      })
      .catch(error => {
        console.log(
          `Wystąpił problem z pobraniem danych do analizy Stetho. ${error}`,
        );
      });

    spd.stetho
      .getCardioResult(examId, testId)
      .then(res => {
        if (mountedRef.current) {
          setCardio(res);
        }
      })
      .catch(error => {
        console.log(
          `Wystąpił problem z pobraniem danych do wyniku serca. ${error}`,
        );
      });

    spd.stetho
      .getPulmonaryResult(examId, testId)
      .then(res => {
        if (mountedRef.current) {
          setPulmonary(res);
        }
      })
      .catch(error => {
        console.log(
          `Wystąpił problem z pobraniem danych do wyniku płuc. ${error}`,
        );
      });

    return () => {
      mountedRef.current = false;
    };
  }, [testId, examId, spd.stetho]);

  return (
    <div className="d-flex flex-column justify-content-center">
      <Card className="main-card">
        <Card.Body className="m-3">
          <Card.Title className="pb-3">Analiza wyników StethoMe</Card.Title>
          <Card.Text>
            <strong>Wynik serca:</strong> {cardio}
          </Card.Text>
          <Card.Text>
            <strong>Wynik płuc:</strong> {pulmonary}
          </Card.Text>

          {frames?.map((frame, key) => (
            <Ratio key={key} aspectRatio="16x9" className="mb-4 w-50">
              <iframe
                src={frame}
                title={`Frame ${key}`}
                allowFullScreen
                style={{ border: 0 }}
              />
            </Ratio>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};
