/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAppointmentChannelDto,
    GetAppointmentChannelDtoFromJSON,
    GetAppointmentChannelDtoFromJSONTyped,
    GetAppointmentChannelDtoToJSON,
    GetExamDto,
    GetExamDtoFromJSON,
    GetExamDtoFromJSONTyped,
    GetExamDtoToJSON,
    GetExamTypeDto,
    GetExamTypeDtoFromJSON,
    GetExamTypeDtoFromJSONTyped,
    GetExamTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetVisitRegistrationDto
 */
export interface GetVisitRegistrationDto {
    /**
     * 
     * @type {string}
     * @memberof GetVisitRegistrationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetVisitRegistrationDto
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetVisitRegistrationDto
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetVisitRegistrationDto
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetVisitRegistrationDto
     */
    mail?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetVisitRegistrationDto
     */
    visitDateTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof GetVisitRegistrationDto
     */
    visitTime?: number | null;
    /**
     * 
     * @type {GetAppointmentChannelDto}
     * @memberof GetVisitRegistrationDto
     */
    appointmentChannel?: GetAppointmentChannelDto | null;
    /**
     * 
     * @type {GetExamTypeDto}
     * @memberof GetVisitRegistrationDto
     */
    examType?: GetExamTypeDto | null;
    /**
     * 
     * @type {GetExamDto}
     * @memberof GetVisitRegistrationDto
     */
    exam?: GetExamDto | null;
}

export function GetVisitRegistrationDtoFromJSON(json: any): GetVisitRegistrationDto {
    return GetVisitRegistrationDtoFromJSONTyped(json, false);
}

export function GetVisitRegistrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVisitRegistrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'mail': !exists(json, 'mail') ? undefined : json['mail'],
        'visitDateTime': !exists(json, 'visitDateTime') ? undefined : (json['visitDateTime'] === null ? null : new Date(json['visitDateTime'])),
        'visitTime': !exists(json, 'visitTime') ? undefined : json['visitTime'],
        'appointmentChannel': !exists(json, 'appointmentChannel') ? undefined : GetAppointmentChannelDtoFromJSON(json['appointmentChannel']),
        'examType': !exists(json, 'examType') ? undefined : GetExamTypeDtoFromJSON(json['examType']),
        'exam': !exists(json, 'exam') ? undefined : GetExamDtoFromJSON(json['exam']),
    };
}

export function GetVisitRegistrationDtoToJSON(value?: GetVisitRegistrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstname': value.firstname,
        'surname': value.surname,
        'phone': value.phone,
        'mail': value.mail,
        'visitDateTime': value.visitDateTime === undefined ? undefined : (value.visitDateTime === null ? null : value.visitDateTime.toISOString()),
        'visitTime': value.visitTime,
        'appointmentChannel': GetAppointmentChannelDtoToJSON(value.appointmentChannel),
        'examType': GetExamTypeDtoToJSON(value.examType),
        'exam': GetExamDtoToJSON(value.exam),
    };
}


