/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAnswerDto
 */
export interface GetAnswerDto {
    /**
     * 
     * @type {string}
     * @memberof GetAnswerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAnswerDto
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnswerDto
     */
    codeAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnswerDto
     */
    lange?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnswerDto
     */
    valueOriginal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnswerDto
     */
    criticalType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnswerDto
     */
    valueType?: string | null;
}

export function GetAnswerDtoFromJSON(json: any): GetAnswerDto {
    return GetAnswerDtoFromJSONTyped(json, false);
}

export function GetAnswerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAnswerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'codeAnswer': !exists(json, 'codeAnswer') ? undefined : json['codeAnswer'],
        'lange': !exists(json, 'lange') ? undefined : json['lange'],
        'valueOriginal': !exists(json, 'valueOriginal') ? undefined : json['valueOriginal'],
        'criticalType': !exists(json, 'criticalType') ? undefined : json['criticalType'],
        'valueType': !exists(json, 'valueType') ? undefined : json['valueType'],
    };
}

export function GetAnswerDtoToJSON(value?: GetAnswerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'value': value.value,
        'codeAnswer': value.codeAnswer,
        'lange': value.lange,
        'valueOriginal': value.valueOriginal,
        'criticalType': value.criticalType,
        'valueType': value.valueType,
    };
}


