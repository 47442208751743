/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientToken
 */
export interface ClientToken {
    /**
     * 
     * @type {string}
     * @memberof ClientToken
     */
    token: string;
}

export function ClientTokenFromJSON(json: any): ClientToken {
    return ClientTokenFromJSONTyped(json, false);
}

export function ClientTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function ClientTokenToJSON(value?: ClientToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}


