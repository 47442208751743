import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import momencik from '../../../common/momencik';
import { GetExamTestDto } from '../../../common/spdCore/autogenerated/spdApiClient';

import { ExamTestPreviewLink } from './ExamTestPreviewLink';
import { StethoPreviewLink } from './StethoPreviewLink';

interface ExamTestsProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const examTestsForGrid = (examTest: GetExamTestDto) => {
  return {
    key: examTest.id,
    values: {
      'exam.number': examTest.exam?.number || '',
      'test.name': examTest.test?.name || '',
      'examTest.startDate': momencik(examTest.startDate, 'YYYY.MM.DD HH:mm:ss'),
      'examTest.endDate': momencik(examTest.endDate, 'YYYY.MM.DD HH:mm:ss'),
      'examTest.criticalPoint': examTest?.criticalPoint && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Zostały oznaczone punkty krytyczne: {examTest.criticalPoint}
            </Tooltip>
          }
        >
          <Badge
            bg="danger"
            className="me-1"
            onClick={e => e.stopPropagation()}
          >
            !
          </Badge>
        </OverlayTrigger>
      ),
      'owner.name': examTest.owner?.name || '',
      preview: (
        <>
          {examTest?.exam?.id && examTest?.test?.id && (
            <ExamTestPreviewLink
              examId={examTest?.exam?.id}
              testId={examTest?.test?.id}
            />
          )}
          {examTest?.test?.identifier === 'med_pomiar_stetoskop' && (
            <StethoPreviewLink
              examId={examTest?.exam?.id}
              testId={examTest?.test?.id}
            />
          )}
        </>
      ),
    },
  };
};

export const ExamTests: React.FC<ExamTestsProps> = props => {
  const columns = [
    { header: '', property: 'examTest.criticalPoint', noSorting: true },
    { header: 'Numer badania', property: 'exam.number' },
    { header: 'Nazwa testu', property: 'test.name' },
    { header: 'Data rozpoczęcia', property: 'examTest.startDate' },
    { header: 'Data zakończenia', property: 'examTest.endDate' },
    { header: 'Ostatni użytkownik', property: 'owner.name' },
    { header: '', property: 'preview', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={false}
      initialOrderColumn="examTest.lp"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak zleconych testów w badaniu"
    />
  );
};
