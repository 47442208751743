/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateVisitRegistrationDto
 */
export interface CreateUpdateVisitRegistrationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateVisitRegistrationDto
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateVisitRegistrationDto
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateVisitRegistrationDto
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateVisitRegistrationDto
     */
    mail?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateVisitRegistrationDto
     */
    visitDateTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateVisitRegistrationDto
     */
    visitTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateVisitRegistrationDto
     */
    appointmentChannelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateVisitRegistrationDto
     */
    examTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateVisitRegistrationDto
     */
    examId?: string | null;
}

export function CreateUpdateVisitRegistrationDtoFromJSON(json: any): CreateUpdateVisitRegistrationDto {
    return CreateUpdateVisitRegistrationDtoFromJSONTyped(json, false);
}

export function CreateUpdateVisitRegistrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateVisitRegistrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'mail': !exists(json, 'mail') ? undefined : json['mail'],
        'visitDateTime': !exists(json, 'visitDateTime') ? undefined : (json['visitDateTime'] === null ? null : new Date(json['visitDateTime'])),
        'visitTime': !exists(json, 'visitTime') ? undefined : json['visitTime'],
        'appointmentChannelId': !exists(json, 'appointmentChannelId') ? undefined : json['appointmentChannelId'],
        'examTypeId': !exists(json, 'examTypeId') ? undefined : json['examTypeId'],
        'examId': !exists(json, 'examId') ? undefined : json['examId'],
    };
}

export function CreateUpdateVisitRegistrationDtoToJSON(value?: CreateUpdateVisitRegistrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstname': value.firstname,
        'surname': value.surname,
        'phone': value.phone,
        'mail': value.mail,
        'visitDateTime': value.visitDateTime === undefined ? undefined : (value.visitDateTime === null ? null : value.visitDateTime.toISOString()),
        'visitTime': value.visitTime,
        'appointmentChannelId': value.appointmentChannelId,
        'examTypeId': value.examTypeId,
        'examId': value.examId,
    };
}


