import { Buffer } from 'buffer';

import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';

import { useSpdCore } from '../../common/hooks/useSpdCore';

export const ChangeLogExternalView: React.FC = () => {
  const spd = useSpdCore();
  const [changelog, setChangelog] = useState<string>();

  useEffect(() => {
    if (process.env.REACT_APP_TEST_CHANGELOG) {
      spd.docs
        .getExternalFile(process.env.REACT_APP_TEST_CHANGELOG)
        .then(changelog => {
          setChangelog(Buffer.from(changelog.content, 'base64').toString());
        })
        .catch(errorResponse => {
          console.log(errorResponse);
        });
    }
  }, [spd.docs]);

  return (
    <>
      <div className="d-flex mt-3 ms-10 ">
        <Markdown className="markdown limited-markdown">{changelog}</Markdown>
      </div>
    </>
  );
};
