import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Layout from '../components/Layout';
import {
  routesInMenu,
  routesOutsideMenu,
  MenuItem,
  routesInLoginMenu,
} from '../../common/navigation';
import ErrorBoundary from '../../common/components/ErrorBoundary/ErrorBoundary';
import AlertModal from '../components/AlertModal';
import { useAuth } from '../../common/hooks/useAuth';
import { useSpdCore } from '../../common/hooks/useSpdCore';
import { useNotifications } from '../../common/hooks/useNotifications';
import { LandingView } from '../../spd/containers/LandingView/LandingView';

const allMenuItemRoutes = (menuitem: MenuItem) => {
  const submenu = menuitem.submenu?.map(subitem => (
    <Route key={subitem.path} path={subitem.path} element={subitem.element} />
  ));
  const item = (
    <Route
      key={menuitem.path}
      path={menuitem.path}
      element={menuitem.element}
    />
  );

  if (!submenu) {
    return item;
  }

  return [...submenu, item];
};

export const MyRoutes: React.FC = () => {
  const auth = useAuth();
  const spd = useSpdCore();
  const notifications = useNotifications();

  const routing = [
    ...routesInMenu
      .filter(r => !r.skipRegistration)
      .map(route => allMenuItemRoutes(route)),
    ...routesInLoginMenu.map(route => allMenuItemRoutes(route)),
    ...routesOutsideMenu.map(route => allMenuItemRoutes(route)),
  ];

  useEffect(() => {
    const user = sessionStorage.getItem('user');
    const cUser = auth.currentUser;

    if (cUser && (!user || !auth.userProfile)) {
      spd.users
        .getByUserUID(cUser?.uid)
        .then(uzytkownik => {
          if (uzytkownik?.id) {
            auth.updateProfile(uzytkownik);
          }
        })
        .catch(error => {
          if (error.message === 'Failed to fetch') {
            notifications.noBackend();
          }
          console.log(`Wystąpił problem z pobraniem profilu. ${error}`);
        });
      sessionStorage.setItem('user', cUser.uid);
    }
  }, [auth, notifications, spd.users]);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Layout>
          <Routes>
            <Route path="/" element={<LandingView />} />
            {routing}
          </Routes>
        </Layout>

        <AlertModal variant="danger">{}</AlertModal>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
