/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateCardDto
 */
export interface CreateUpdateCardDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    number?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCardDto
     */
    pass?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    justification?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateCardDto
     */
    postDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    interview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    intellectualAbility?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    personality: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    psychomotorEfficiency: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    conclusions: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    recommendations: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    examId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    psychologistUID?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateCardDto
     */
    cardDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    pdfTemplateType?: CreateUpdateCardDtoPdfTemplateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalSkin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalSkull?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalLymphNodes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalNose?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalOropharyngealCavity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalNeck?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalChest?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalLungs?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalCardiovascularSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalAbdominalCavity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalGenitourinarySystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalLocomotorSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalNervousSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    physicalMentalState?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateCardDto
     */
    nextExamDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    type?: CreateUpdateCardDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    eyeball?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    facialAssymetry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    breathMurmur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    regularity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    heartSounds?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    heartRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    restRR?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    hernias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    liver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    muscleStrengthUpper?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    handGrip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    limitationFingers?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    limitationElbows?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    limitationShoulder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    limitationFeet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    limitationKnee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    movementCervical?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    movementLumbar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    anomalies?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    upperLimbParalysis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    lowerLimbParalysis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    muscleAtrophy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCardDto
     */
    gaitTest?: string | null;
}

export function CreateUpdateCardDtoFromJSON(json: any): CreateUpdateCardDto {
    return CreateUpdateCardDtoFromJSONTyped(json, false);
}

export function CreateUpdateCardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateCardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'pass': !exists(json, 'pass') ? undefined : json['pass'],
        'justification': !exists(json, 'justification') ? undefined : json['justification'],
        'postDate': !exists(json, 'postDate') ? undefined : (json['postDate'] === null ? null : new Date(json['postDate'])),
        'interview': !exists(json, 'interview') ? undefined : json['interview'],
        'intellectualAbility': !exists(json, 'intellectualAbility') ? undefined : json['intellectualAbility'],
        'personality': json['personality'],
        'psychomotorEfficiency': json['psychomotorEfficiency'],
        'conclusions': json['conclusions'],
        'recommendations': json['recommendations'],
        'examId': !exists(json, 'examId') ? undefined : json['examId'],
        'psychologistUID': !exists(json, 'psychologistUID') ? undefined : json['psychologistUID'],
        'cardDate': !exists(json, 'cardDate') ? undefined : (json['cardDate'] === null ? null : new Date(json['cardDate'])),
        'pdfTemplateType': !exists(json, 'pdfTemplateType') ? undefined : json['pdfTemplateType'],
        'physicalSkin': !exists(json, 'physicalSkin') ? undefined : json['physicalSkin'],
        'physicalSkull': !exists(json, 'physicalSkull') ? undefined : json['physicalSkull'],
        'physicalLymphNodes': !exists(json, 'physicalLymphNodes') ? undefined : json['physicalLymphNodes'],
        'physicalNose': !exists(json, 'physicalNose') ? undefined : json['physicalNose'],
        'physicalOropharyngealCavity': !exists(json, 'physicalOropharyngealCavity') ? undefined : json['physicalOropharyngealCavity'],
        'physicalNeck': !exists(json, 'physicalNeck') ? undefined : json['physicalNeck'],
        'physicalChest': !exists(json, 'physicalChest') ? undefined : json['physicalChest'],
        'physicalLungs': !exists(json, 'physicalLungs') ? undefined : json['physicalLungs'],
        'physicalCardiovascularSystem': !exists(json, 'physicalCardiovascularSystem') ? undefined : json['physicalCardiovascularSystem'],
        'physicalAbdominalCavity': !exists(json, 'physicalAbdominalCavity') ? undefined : json['physicalAbdominalCavity'],
        'physicalGenitourinarySystem': !exists(json, 'physicalGenitourinarySystem') ? undefined : json['physicalGenitourinarySystem'],
        'physicalLocomotorSystem': !exists(json, 'physicalLocomotorSystem') ? undefined : json['physicalLocomotorSystem'],
        'physicalNervousSystem': !exists(json, 'physicalNervousSystem') ? undefined : json['physicalNervousSystem'],
        'physicalMentalState': !exists(json, 'physicalMentalState') ? undefined : json['physicalMentalState'],
        'nextExamDate': !exists(json, 'nextExamDate') ? undefined : (json['nextExamDate'] === null ? null : new Date(json['nextExamDate'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'eyeball': !exists(json, 'eyeball') ? undefined : json['eyeball'],
        'facialAssymetry': !exists(json, 'facialAssymetry') ? undefined : json['facialAssymetry'],
        'breathMurmur': !exists(json, 'breathMurmur') ? undefined : json['breathMurmur'],
        'regularity': !exists(json, 'regularity') ? undefined : json['regularity'],
        'heartSounds': !exists(json, 'heartSounds') ? undefined : json['heartSounds'],
        'heartRate': !exists(json, 'heartRate') ? undefined : json['heartRate'],
        'restRR': !exists(json, 'restRR') ? undefined : json['restRR'],
        'hernias': !exists(json, 'hernias') ? undefined : json['hernias'],
        'liver': !exists(json, 'liver') ? undefined : json['liver'],
        'muscleStrengthUpper': !exists(json, 'muscleStrengthUpper') ? undefined : json['muscleStrengthUpper'],
        'handGrip': !exists(json, 'handGrip') ? undefined : json['handGrip'],
        'limitationFingers': !exists(json, 'limitationFingers') ? undefined : json['limitationFingers'],
        'limitationElbows': !exists(json, 'limitationElbows') ? undefined : json['limitationElbows'],
        'limitationShoulder': !exists(json, 'limitationShoulder') ? undefined : json['limitationShoulder'],
        'limitationFeet': !exists(json, 'limitationFeet') ? undefined : json['limitationFeet'],
        'limitationKnee': !exists(json, 'limitationKnee') ? undefined : json['limitationKnee'],
        'movementCervical': !exists(json, 'movementCervical') ? undefined : json['movementCervical'],
        'movementLumbar': !exists(json, 'movementLumbar') ? undefined : json['movementLumbar'],
        'anomalies': !exists(json, 'anomalies') ? undefined : json['anomalies'],
        'upperLimbParalysis': !exists(json, 'upperLimbParalysis') ? undefined : json['upperLimbParalysis'],
        'lowerLimbParalysis': !exists(json, 'lowerLimbParalysis') ? undefined : json['lowerLimbParalysis'],
        'muscleAtrophy': !exists(json, 'muscleAtrophy') ? undefined : json['muscleAtrophy'],
        'gaitTest': !exists(json, 'gaitTest') ? undefined : json['gaitTest'],
    };
}

export function CreateUpdateCardDtoToJSON(value?: CreateUpdateCardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'pass': value.pass,
        'justification': value.justification,
        'postDate': value.postDate === undefined ? undefined : (value.postDate === null ? null : value.postDate.toISOString()),
        'interview': value.interview,
        'intellectualAbility': value.intellectualAbility,
        'personality': value.personality,
        'psychomotorEfficiency': value.psychomotorEfficiency,
        'conclusions': value.conclusions,
        'recommendations': value.recommendations,
        'examId': value.examId,
        'psychologistUID': value.psychologistUID,
        'cardDate': value.cardDate === undefined ? undefined : (value.cardDate === null ? null : value.cardDate.toISOString()),
        'pdfTemplateType': value.pdfTemplateType,
        'physicalSkin': value.physicalSkin,
        'physicalSkull': value.physicalSkull,
        'physicalLymphNodes': value.physicalLymphNodes,
        'physicalNose': value.physicalNose,
        'physicalOropharyngealCavity': value.physicalOropharyngealCavity,
        'physicalNeck': value.physicalNeck,
        'physicalChest': value.physicalChest,
        'physicalLungs': value.physicalLungs,
        'physicalCardiovascularSystem': value.physicalCardiovascularSystem,
        'physicalAbdominalCavity': value.physicalAbdominalCavity,
        'physicalGenitourinarySystem': value.physicalGenitourinarySystem,
        'physicalLocomotorSystem': value.physicalLocomotorSystem,
        'physicalNervousSystem': value.physicalNervousSystem,
        'physicalMentalState': value.physicalMentalState,
        'nextExamDate': value.nextExamDate === undefined ? undefined : (value.nextExamDate === null ? null : value.nextExamDate.toISOString()),
        'type': value.type,
        'eyeball': value.eyeball,
        'facialAssymetry': value.facialAssymetry,
        'breathMurmur': value.breathMurmur,
        'regularity': value.regularity,
        'heartSounds': value.heartSounds,
        'heartRate': value.heartRate,
        'restRR': value.restRR,
        'hernias': value.hernias,
        'liver': value.liver,
        'muscleStrengthUpper': value.muscleStrengthUpper,
        'handGrip': value.handGrip,
        'limitationFingers': value.limitationFingers,
        'limitationElbows': value.limitationElbows,
        'limitationShoulder': value.limitationShoulder,
        'limitationFeet': value.limitationFeet,
        'limitationKnee': value.limitationKnee,
        'movementCervical': value.movementCervical,
        'movementLumbar': value.movementLumbar,
        'anomalies': value.anomalies,
        'upperLimbParalysis': value.upperLimbParalysis,
        'lowerLimbParalysis': value.lowerLimbParalysis,
        'muscleAtrophy': value.muscleAtrophy,
        'gaitTest': value.gaitTest,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateCardDtoPdfTemplateTypeEnum {
    Art82Ust1 = 'art_82_ust_1',
    Art82Uprzywilejowany = 'art_82_uprzywilejowany',
    Art82InstruktorEgzaminator = 'art_82_instruktor_egzaminator',
    Art39TransportDrogowy = 'art_39_transport_drogowy',
    MedycynaPracy = 'medycyna_pracy',
    KartaBadaniaPsychologicznego = 'karta_badania_psychologicznego',
    KartaBroni = 'karta_broni',
    BronOrzeczenieLekarskie = 'bron_orzeczenie_lekarskie',
    BronOrzeczeniePsychologiczne = 'bron_orzeczenie_psychologiczne',
    BronKartaBadaniaPsychologicznego = 'bron_karta_badania_psychologicznego',
    KartaBadaniaProfilaktycznego = 'karta_badania_profilaktycznego',
    MpOrzeczenieLekarskie43 = 'mp_orzeczenie_lekarskie_43',
    MpOrzeczenieLekarskie43Ang = 'mp_orzeczenie_lekarskie_43_ang',
    MpOrzeczenieLekarskie43Transport = 'mp_orzeczenie_lekarskie_43_transport',
    KierowcaOrzeczenieLekarskieArt75Uprzywilejowany = 'kierowca_orzeczenie_lekarskie_art_75_uprzywilejowany',
    KierowcaOrzeczenieLekarskieArt75Reszta = 'kierowca_orzeczenie_lekarskie_art_75_reszta',
    MpZaswiadczenieLekarskieStudent = 'mp_zaswiadczenie_lekarskie_student',
    OrzeczenieSanitarnoEpidemiologiczne = 'orzeczenie_sanitarno_epidemiologiczne',
    KartaBadaniaKierowcy = 'karta_badania_kierowcy',
    KonsultacjaNeurologiczna = 'konsultacja_neurologiczna',
    KonsultacjaLaryngologiczna = 'konsultacja_laryngologiczna',
    KonsultacjaOkulistyczna = 'konsultacja_okulistyczna',
    KonsultacjaPsychiatryczna = 'konsultacja_psychiatryczna',
    KartaOdpowiedzi = 'karta_odpowiedzi',
    KartaBadaniaSanitarnoEpidemiologicznego = 'karta_badania_sanitarno_epidemiologicznego',
    ZaswiadczenieLekarskieZdolnosciDoPracy = 'zaswiadczenie_lekarskie_zdolnosci_do_pracy',
    ZaswiadczenieSportowe = 'zaswiadczenie_sportowe',
    KartaBadaniaSportowego = 'karta_badania_sportowego',
    OrzeczenieStanZdrowiaLekarza = 'orzeczenie_stan_zdrowia_lekarza',
    ZaswiadczenieStanZdrowiaPielegniarka = 'zaswiadczenie_stan_zdrowia_pielegniarka',
    ZaswiadczenieStanZdrowiaPielegniarkaArt31 = 'zaswiadczenie_stan_zdrowia_pielegniarka_art31',
    RejestrOrzeczen = 'rejestrOrzeczen',
    RejestrMedycynaPracy = 'rejestrMedycynaPracy',
    RejestrKierowcow = 'rejestrKierowcow',
    SkierowanieUczen = 'skierowanie_uczen',
    SkierowaniePracownik = 'skierowanie_pracownik',
    WynikWidzeniaZmierzchowego = 'wynik_widzenia_zmierzchowego',
    WybuchCywilneOrzeczenieLekarskie = 'wybuch_cywilne_orzeczenie_lekarskie',
    WybuchCywilneOrzeczeniePsychologiczne = 'wybuch_cywilne_orzeczenie_psychologiczne',
    WybuchWojskoOrzeczenieLekarskie = 'wybuch_wojsko_orzeczenie_lekarskie',
    WybuchWojskoOrzeczeniePsychologiczne = 'wybuch_wojsko_orzeczenie_psychologiczne',
    DetektywOrzeczenieLekarskie = 'detektyw_orzeczenie_lekarskie',
    DetektywOrzeczeniePsychologiczne = 'detektyw_orzeczenie_psychologiczne',
    ZestawienieOrzeczen = 'zestawienie_orzeczen',
    ListaFirm = 'lista_firm',
    SkierowanieDoPoradni = 'skierowanie_do_poradni'
}
/**
* @export
* @enum {string}
*/
export enum CreateUpdateCardDtoTypeEnum {
    Preventive = 'Preventive',
    Psychologist = 'Psychologist',
    PsychologistWeapon = 'PsychologistWeapon',
    Weapon = 'Weapon',
    Driver = 'Driver',
    Sanitary = 'Sanitary',
    Sport = 'Sport'
}


