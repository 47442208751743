/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateConsultationDto
 */
export interface CreateUpdateConsultationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    examId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateConsultationDto
     */
    consultDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    type?: CreateUpdateConsultationDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    authorUID?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateConsultationDto
     */
    pass?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    descriptionExam?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    interview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    recommendations?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    pdfTemplateType?: CreateUpdateConsultationDtoPdfTemplateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    recognition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    contraindications?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    acuityDistanceOd?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    acuityDistanceOs?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    acuityNearNod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    acuityNearNos?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    pseudoisochromaticPlates?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    slitLamp?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateConsultationDto
     */
    spatialVision?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateConsultationDto
     */
    fieldOfVisionRight?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateConsultationDto
     */
    fieldOfVisionLeft?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    eyeAlignment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    alternateCover?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    convergence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    acuityWithCorrectionOd?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    acuityWithCorrectionOs?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    protectiveDeviceConjunctiva?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    protectiveDeviceEyelids?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    anteriorSegment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    fundusOfEye?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    otoscopyRight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    otoscopyLeft?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    throatAndLarynx?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    inflammatoryChanges?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    audiologicalDistance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    audiologicalRight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateConsultationDto
     */
    audiologicalLeft?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateConsultationDto
     */
    romberg?: boolean | null;
}

export function CreateUpdateConsultationDtoFromJSON(json: any): CreateUpdateConsultationDto {
    return CreateUpdateConsultationDtoFromJSONTyped(json, false);
}

export function CreateUpdateConsultationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateConsultationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'examId': !exists(json, 'examId') ? undefined : json['examId'],
        'consultDate': !exists(json, 'consultDate') ? undefined : (json['consultDate'] === null ? null : new Date(json['consultDate'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'authorUID': !exists(json, 'authorUID') ? undefined : json['authorUID'],
        'pass': !exists(json, 'pass') ? undefined : json['pass'],
        'descriptionExam': !exists(json, 'descriptionExam') ? undefined : json['descriptionExam'],
        'interview': !exists(json, 'interview') ? undefined : json['interview'],
        'recommendations': !exists(json, 'recommendations') ? undefined : json['recommendations'],
        'pdfTemplateType': !exists(json, 'pdfTemplateType') ? undefined : json['pdfTemplateType'],
        'recognition': !exists(json, 'recognition') ? undefined : json['recognition'],
        'contraindications': !exists(json, 'contraindications') ? undefined : json['contraindications'],
        'acuityDistanceOd': !exists(json, 'acuityDistanceOd') ? undefined : json['acuityDistanceOd'],
        'acuityDistanceOs': !exists(json, 'acuityDistanceOs') ? undefined : json['acuityDistanceOs'],
        'acuityNearNod': !exists(json, 'acuityNearNod') ? undefined : json['acuityNearNod'],
        'acuityNearNos': !exists(json, 'acuityNearNos') ? undefined : json['acuityNearNos'],
        'pseudoisochromaticPlates': !exists(json, 'pseudoisochromaticPlates') ? undefined : json['pseudoisochromaticPlates'],
        'slitLamp': !exists(json, 'slitLamp') ? undefined : json['slitLamp'],
        'spatialVision': !exists(json, 'spatialVision') ? undefined : json['spatialVision'],
        'fieldOfVisionRight': !exists(json, 'fieldOfVisionRight') ? undefined : json['fieldOfVisionRight'],
        'fieldOfVisionLeft': !exists(json, 'fieldOfVisionLeft') ? undefined : json['fieldOfVisionLeft'],
        'eyeAlignment': !exists(json, 'eyeAlignment') ? undefined : json['eyeAlignment'],
        'alternateCover': !exists(json, 'alternateCover') ? undefined : json['alternateCover'],
        'convergence': !exists(json, 'convergence') ? undefined : json['convergence'],
        'acuityWithCorrectionOd': !exists(json, 'acuityWithCorrectionOd') ? undefined : json['acuityWithCorrectionOd'],
        'acuityWithCorrectionOs': !exists(json, 'acuityWithCorrectionOs') ? undefined : json['acuityWithCorrectionOs'],
        'protectiveDeviceConjunctiva': !exists(json, 'protectiveDeviceConjunctiva') ? undefined : json['protectiveDeviceConjunctiva'],
        'protectiveDeviceEyelids': !exists(json, 'protectiveDeviceEyelids') ? undefined : json['protectiveDeviceEyelids'],
        'anteriorSegment': !exists(json, 'anteriorSegment') ? undefined : json['anteriorSegment'],
        'fundusOfEye': !exists(json, 'fundusOfEye') ? undefined : json['fundusOfEye'],
        'otoscopyRight': !exists(json, 'otoscopyRight') ? undefined : json['otoscopyRight'],
        'otoscopyLeft': !exists(json, 'otoscopyLeft') ? undefined : json['otoscopyLeft'],
        'throatAndLarynx': !exists(json, 'throatAndLarynx') ? undefined : json['throatAndLarynx'],
        'inflammatoryChanges': !exists(json, 'inflammatoryChanges') ? undefined : json['inflammatoryChanges'],
        'audiologicalDistance': !exists(json, 'audiologicalDistance') ? undefined : json['audiologicalDistance'],
        'audiologicalRight': !exists(json, 'audiologicalRight') ? undefined : json['audiologicalRight'],
        'audiologicalLeft': !exists(json, 'audiologicalLeft') ? undefined : json['audiologicalLeft'],
        'romberg': !exists(json, 'romberg') ? undefined : json['romberg'],
    };
}

export function CreateUpdateConsultationDtoToJSON(value?: CreateUpdateConsultationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'examId': value.examId,
        'consultDate': value.consultDate === undefined ? undefined : (value.consultDate === null ? null : value.consultDate.toISOString()),
        'type': value.type,
        'authorUID': value.authorUID,
        'pass': value.pass,
        'descriptionExam': value.descriptionExam,
        'interview': value.interview,
        'recommendations': value.recommendations,
        'pdfTemplateType': value.pdfTemplateType,
        'recognition': value.recognition,
        'contraindications': value.contraindications,
        'acuityDistanceOd': value.acuityDistanceOd,
        'acuityDistanceOs': value.acuityDistanceOs,
        'acuityNearNod': value.acuityNearNod,
        'acuityNearNos': value.acuityNearNos,
        'pseudoisochromaticPlates': value.pseudoisochromaticPlates,
        'slitLamp': value.slitLamp,
        'spatialVision': value.spatialVision,
        'fieldOfVisionRight': value.fieldOfVisionRight,
        'fieldOfVisionLeft': value.fieldOfVisionLeft,
        'eyeAlignment': value.eyeAlignment,
        'alternateCover': value.alternateCover,
        'convergence': value.convergence,
        'acuityWithCorrectionOd': value.acuityWithCorrectionOd,
        'acuityWithCorrectionOs': value.acuityWithCorrectionOs,
        'protectiveDeviceConjunctiva': value.protectiveDeviceConjunctiva,
        'protectiveDeviceEyelids': value.protectiveDeviceEyelids,
        'anteriorSegment': value.anteriorSegment,
        'fundusOfEye': value.fundusOfEye,
        'otoscopyRight': value.otoscopyRight,
        'otoscopyLeft': value.otoscopyLeft,
        'throatAndLarynx': value.throatAndLarynx,
        'inflammatoryChanges': value.inflammatoryChanges,
        'audiologicalDistance': value.audiologicalDistance,
        'audiologicalRight': value.audiologicalRight,
        'audiologicalLeft': value.audiologicalLeft,
        'romberg': value.romberg,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateConsultationDtoTypeEnum {
    Neurological = 'Neurological',
    Ophthalmologist = 'Ophthalmologist',
    ENT = 'ENT',
    Psychiatric = 'Psychiatric'
}
/**
* @export
* @enum {string}
*/
export enum CreateUpdateConsultationDtoPdfTemplateTypeEnum {
    Art82Ust1 = 'art_82_ust_1',
    Art82Uprzywilejowany = 'art_82_uprzywilejowany',
    Art82InstruktorEgzaminator = 'art_82_instruktor_egzaminator',
    Art39TransportDrogowy = 'art_39_transport_drogowy',
    MedycynaPracy = 'medycyna_pracy',
    KartaBadaniaPsychologicznego = 'karta_badania_psychologicznego',
    KartaBroni = 'karta_broni',
    BronOrzeczenieLekarskie = 'bron_orzeczenie_lekarskie',
    BronOrzeczeniePsychologiczne = 'bron_orzeczenie_psychologiczne',
    BronKartaBadaniaPsychologicznego = 'bron_karta_badania_psychologicznego',
    KartaBadaniaProfilaktycznego = 'karta_badania_profilaktycznego',
    MpOrzeczenieLekarskie43 = 'mp_orzeczenie_lekarskie_43',
    MpOrzeczenieLekarskie43Ang = 'mp_orzeczenie_lekarskie_43_ang',
    MpOrzeczenieLekarskie43Transport = 'mp_orzeczenie_lekarskie_43_transport',
    KierowcaOrzeczenieLekarskieArt75Uprzywilejowany = 'kierowca_orzeczenie_lekarskie_art_75_uprzywilejowany',
    KierowcaOrzeczenieLekarskieArt75Reszta = 'kierowca_orzeczenie_lekarskie_art_75_reszta',
    MpZaswiadczenieLekarskieStudent = 'mp_zaswiadczenie_lekarskie_student',
    OrzeczenieSanitarnoEpidemiologiczne = 'orzeczenie_sanitarno_epidemiologiczne',
    KartaBadaniaKierowcy = 'karta_badania_kierowcy',
    KonsultacjaNeurologiczna = 'konsultacja_neurologiczna',
    KonsultacjaLaryngologiczna = 'konsultacja_laryngologiczna',
    KonsultacjaOkulistyczna = 'konsultacja_okulistyczna',
    KonsultacjaPsychiatryczna = 'konsultacja_psychiatryczna',
    KartaOdpowiedzi = 'karta_odpowiedzi',
    KartaBadaniaSanitarnoEpidemiologicznego = 'karta_badania_sanitarno_epidemiologicznego',
    ZaswiadczenieLekarskieZdolnosciDoPracy = 'zaswiadczenie_lekarskie_zdolnosci_do_pracy',
    ZaswiadczenieSportowe = 'zaswiadczenie_sportowe',
    KartaBadaniaSportowego = 'karta_badania_sportowego',
    OrzeczenieStanZdrowiaLekarza = 'orzeczenie_stan_zdrowia_lekarza',
    ZaswiadczenieStanZdrowiaPielegniarka = 'zaswiadczenie_stan_zdrowia_pielegniarka',
    ZaswiadczenieStanZdrowiaPielegniarkaArt31 = 'zaswiadczenie_stan_zdrowia_pielegniarka_art31',
    RejestrOrzeczen = 'rejestrOrzeczen',
    RejestrMedycynaPracy = 'rejestrMedycynaPracy',
    RejestrKierowcow = 'rejestrKierowcow',
    SkierowanieUczen = 'skierowanie_uczen',
    SkierowaniePracownik = 'skierowanie_pracownik',
    WynikWidzeniaZmierzchowego = 'wynik_widzenia_zmierzchowego',
    WybuchCywilneOrzeczenieLekarskie = 'wybuch_cywilne_orzeczenie_lekarskie',
    WybuchCywilneOrzeczeniePsychologiczne = 'wybuch_cywilne_orzeczenie_psychologiczne',
    WybuchWojskoOrzeczenieLekarskie = 'wybuch_wojsko_orzeczenie_lekarskie',
    WybuchWojskoOrzeczeniePsychologiczne = 'wybuch_wojsko_orzeczenie_psychologiczne',
    DetektywOrzeczenieLekarskie = 'detektyw_orzeczenie_lekarskie',
    DetektywOrzeczeniePsychologiczne = 'detektyw_orzeczenie_psychologiczne',
    ZestawienieOrzeczen = 'zestawienie_orzeczen',
    ListaFirm = 'lista_firm',
    SkierowanieDoPoradni = 'skierowanie_do_poradni'
}


