/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPdfTemplateDto
 */
export interface GetPdfTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    type: GetPdfTemplateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    headerFirstPage: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    footer: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    footerFirstPage: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    margins: string;
}

export function GetPdfTemplateDtoFromJSON(json: any): GetPdfTemplateDto {
    return GetPdfTemplateDtoFromJSONTyped(json, false);
}

export function GetPdfTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPdfTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'template': json['template'],
        'header': json['header'],
        'headerFirstPage': json['headerFirstPage'],
        'footer': json['footer'],
        'footerFirstPage': json['footerFirstPage'],
        'margins': json['margins'],
    };
}

export function GetPdfTemplateDtoToJSON(value?: GetPdfTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'template': value.template,
        'header': value.header,
        'headerFirstPage': value.headerFirstPage,
        'footer': value.footer,
        'footerFirstPage': value.footerFirstPage,
        'margins': value.margins,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetPdfTemplateDtoTypeEnum {
    Art82Ust1 = 'art_82_ust_1',
    Art82Uprzywilejowany = 'art_82_uprzywilejowany',
    Art82InstruktorEgzaminator = 'art_82_instruktor_egzaminator',
    Art39TransportDrogowy = 'art_39_transport_drogowy',
    MedycynaPracy = 'medycyna_pracy',
    KartaBadaniaPsychologicznego = 'karta_badania_psychologicznego',
    KartaBroni = 'karta_broni',
    BronOrzeczenieLekarskie = 'bron_orzeczenie_lekarskie',
    BronOrzeczeniePsychologiczne = 'bron_orzeczenie_psychologiczne',
    BronKartaBadaniaPsychologicznego = 'bron_karta_badania_psychologicznego',
    KartaBadaniaProfilaktycznego = 'karta_badania_profilaktycznego',
    MpOrzeczenieLekarskie43 = 'mp_orzeczenie_lekarskie_43',
    MpOrzeczenieLekarskie43Ang = 'mp_orzeczenie_lekarskie_43_ang',
    MpOrzeczenieLekarskie43Transport = 'mp_orzeczenie_lekarskie_43_transport',
    KierowcaOrzeczenieLekarskieArt75Uprzywilejowany = 'kierowca_orzeczenie_lekarskie_art_75_uprzywilejowany',
    KierowcaOrzeczenieLekarskieArt75Reszta = 'kierowca_orzeczenie_lekarskie_art_75_reszta',
    MpZaswiadczenieLekarskieStudent = 'mp_zaswiadczenie_lekarskie_student',
    OrzeczenieSanitarnoEpidemiologiczne = 'orzeczenie_sanitarno_epidemiologiczne',
    KartaBadaniaKierowcy = 'karta_badania_kierowcy',
    KonsultacjaNeurologiczna = 'konsultacja_neurologiczna',
    KonsultacjaLaryngologiczna = 'konsultacja_laryngologiczna',
    KonsultacjaOkulistyczna = 'konsultacja_okulistyczna',
    KonsultacjaPsychiatryczna = 'konsultacja_psychiatryczna',
    KartaOdpowiedzi = 'karta_odpowiedzi',
    KartaBadaniaSanitarnoEpidemiologicznego = 'karta_badania_sanitarno_epidemiologicznego',
    ZaswiadczenieLekarskieZdolnosciDoPracy = 'zaswiadczenie_lekarskie_zdolnosci_do_pracy',
    ZaswiadczenieSportowe = 'zaswiadczenie_sportowe',
    KartaBadaniaSportowego = 'karta_badania_sportowego',
    OrzeczenieStanZdrowiaLekarza = 'orzeczenie_stan_zdrowia_lekarza',
    ZaswiadczenieStanZdrowiaPielegniarka = 'zaswiadczenie_stan_zdrowia_pielegniarka',
    ZaswiadczenieStanZdrowiaPielegniarkaArt31 = 'zaswiadczenie_stan_zdrowia_pielegniarka_art31',
    RejestrOrzeczen = 'rejestrOrzeczen',
    RejestrMedycynaPracy = 'rejestrMedycynaPracy',
    RejestrKierowcow = 'rejestrKierowcow',
    SkierowanieUczen = 'skierowanie_uczen',
    SkierowaniePracownik = 'skierowanie_pracownik',
    WynikWidzeniaZmierzchowego = 'wynik_widzenia_zmierzchowego',
    WybuchCywilneOrzeczenieLekarskie = 'wybuch_cywilne_orzeczenie_lekarskie',
    WybuchCywilneOrzeczeniePsychologiczne = 'wybuch_cywilne_orzeczenie_psychologiczne',
    WybuchWojskoOrzeczenieLekarskie = 'wybuch_wojsko_orzeczenie_lekarskie',
    WybuchWojskoOrzeczeniePsychologiczne = 'wybuch_wojsko_orzeczenie_psychologiczne',
    DetektywOrzeczenieLekarskie = 'detektyw_orzeczenie_lekarskie',
    DetektywOrzeczeniePsychologiczne = 'detektyw_orzeczenie_psychologiczne',
    ZestawienieOrzeczen = 'zestawienie_orzeczen',
    ListaFirm = 'lista_firm',
    SkierowanieDoPoradni = 'skierowanie_do_poradni'
}


