import React from 'react';
import {
  Button as ButtonBootstrap,
  // eslint-disable-next-line import/named
  ButtonProps as ButtonBootstrapProps,
  Spinner,
} from 'react-bootstrap';

interface ButtonProps extends ButtonBootstrapProps {
  isLoading?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
}

const SpinnerButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, isLoading, disabled, ...props }, ref) => {
    return (
      <ButtonBootstrap ref={ref} {...props} disabled={disabled || isLoading}>
        {isLoading && <Spinner animation="border" size="sm" className="me-1" />}
        {children}
      </ButtonBootstrap>
    );
  },
);

SpinnerButton.displayName = 'Button';

export default SpinnerButton;
